import React from "react";

import Layout from "../components/layout";
import SEO from "gatsby-plugin-wpgraphql-seo";

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="Pagina non trovata" />
      <div>
        <h1 className="text-4xl text-center text-bold">La pagina non è stata trovata</h1>
      </div>
    </Layout>
  );
}

export default NotFoundPage;
